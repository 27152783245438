<template>
  <window-content>
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border size="2" v-model="servidor.id" disable/>
          </erp-s-field>
        </e-col>

      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="110px"
          >
            <erp-input size="2" simple-border v-model="servidor.nome"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Endpoint:"
          >
            <erp-input size="2" simple-border v-model="servidor.url"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Token:"
              label-width="110px"
          >
            <erp-input size="2" simple-border v-model="servidor.token"/>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <erp-tabs ref="tab" @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="IP(s):"
                label-width="110px"
                icon-help="Separe-os por linha"
            >
              <textarea rows="4" v-model="servidor.ips" class="full-width" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="Descrição:"
                label-width="110px"
            >
             <textarea rows="4" v-model="servidor.descricao" class="full-width" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Configuração:"
                label-width="110px"
            >
              <div class="erp-textarea" style="min-height: 200px" ref="configEditor" :contenteditable="true" v-html="servidor.config">
              </div>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn v-if="servidor.id" class="m-r-sm pull-left" color="negative" label="Cadastrar novo" icon="plus-add" flat
               icon-type="fa" icon-style="light"
               no-caps
               @click="reset"/>

        <u-btn class="m-r-sm" color="white" label="Cancelar" icon="chevron-left" flat icon-type="fa" icon-style="light"
               no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn :loading="loading" color="positive" label="Salvar (F2)"
               icon="save" icon-type="fa" icon-style="light" no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save"/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import {date, UCheckbox} from 'uloc-vue'
import {
  find,
  newServidor,
  updateServidor
} from '../../../../../domain/gerencia/servidor/services/index'
import DatetimeInput from '../../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../../reuse/input/DefaultAsyncSelect'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'

const mockServidor = {
  id: null,
  nome: null,
  url: null,
  token: null,
  ips: [],
  descricao: null,
  config: {},
  active: true
}

const initialState = function () {
  return {
    tabActive: 'principal',
    loading: false,
    servidor: JSON.parse(JSON.stringify(mockServidor))
  }
}

export default {
  name: 'ServidorWindow',
  props: ['id'],
  data() {
    return initialState.call(this)
  },
  mounted() {
    this.load()
  },
  beforeDestroy() {
  },
  computed: {},
  watch: {},
  methods: {
    getId() {
      return this.servidor && this.servidor.id ? this.servidor.id : this.id
    },
    reset() {
      Object.assign(this.$data, initialState.call(this))
    },
    load() {
      this.loading = !!this.getId()
      this.getId() && find(this.getId())
          .then(response => {
            const data = response.data
            try {
              data.config = JSON.stringify(JSON.parse(data.config), undefined, 4)
              data.config = !data.config || 'null' === data.config ? {} : data.config
            } catch (e) {
              data.config = {}
            }
            try {
              data.ips = JSON.parse(data.ips)
              if (Array.isArray(data.ips)) {
                data.ips = data.ips.join('\n')
              }
            } catch (e) {
              data.ips = ''
            }
            this.servidor = data
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar os dados do servidor, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    changeTab({tab}) {
      this.tabActive = tab
    },
    save() {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.servidor))
      data.config = this.$refs.configEditor.innerText
      data.config = JSON.stringify(data.config)
      data.ips = JSON.stringify(String(data.ips).split(/(?:\r\n|\r|\n)/g))
      let method = data.id ? updateServidor(data.id, data) : newServidor(data)
      method
          .then(({data}) => {
            let isNew = !!!this.servidor.id
            this.servidor.id = data.id
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                // this.$refs.timer.start()
                this.loading = false
                this.success = false
                let title = 'Registro de servidor'
                let message = 'Servidor registrado com sucesso!'
                if (!isNew) {
                  title = 'Cadastro de servidor'
                  message = 'Servidor atualizado com sucesso!'
                }
                this.dg(title, message)
                // this.load()
                this.$uloc.window.emit(this.$root.wid, 'update', this.servidor.id)
              })
            }

            cbSuccess()

          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    }
  },
  components: {
    ECol,
    ERow,
    DefaultAsyncSelect,
    WindowFooter,
    WindowBody,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    ErpLabel,
    ErpSelect,
    DatetimeInput,
    ErpCheckbox,
    UCheckbox
  }
}
</script>
